import Swiper, { Navigation, Grid } from "swiper";

$(function () {
    let $sliders = $('.video-slider');

    if ($sliders.length > 0) {
        $sliders.each(function () {
            let el = $(this);

            let $arrow_left = el.find('.swiper-button-prev')[0];
            let $arrow_right = el.find('.swiper-button-next')[0];

            new Swiper(el.find('.swiper')[0], {
                modules: [Navigation, Grid],
                navigation: {
                    nextEl: $arrow_right,
                    prevEl: $arrow_left,
                },
                grabCursor: true,
                breakpoints: {
                    375: {
                        slidesPerView: 1.2,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                        grid: {
                            rows: 2,
                            fill: 'row',
                        },
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                        grid: {
                            rows: 2,
                            fill: 'row',
                        },
                    }
                }
            });
        });
    }
});