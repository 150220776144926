window.$ = window.jQuery = require('jquery');
window.axios = require('axios');

// require('./components/Menu');
require('./components/Map');
require('./components/Slider');
require('./components/Menu');
require('./components/Filters');

//Bootstrap import
require('@popperjs/core/dist/esm/popper');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

$(function () {
    let $main = $('main');
    let $header = $('#top');
    let $top_nav = $('#top-nav');
    let $navbar = $('#navbarToggleExternalContent');
    let $footer = $('#footer');
    let $window_h = window.innerHeight;
    let $404_page = $('.uq-heading-404');
    let $sameHeightDivs = $('#swiper-main-wrapper .swiper-slide .card');

    function setSameHeight() {
        let minHeight = 0;

        $sameHeightDivs.each(function () {
            minHeight = Math.max(minHeight, $(this).outerHeight());
        });

        $sameHeightDivs.css({'min-height': minHeight + 'px'});
    }

    function resetSameHeight() {
        $sameHeightDivs.css({'min-height': 'unset'});
    }

    function setMainContentHeight() {
        if ($window_h > $main.outerHeight()) {
            $main.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight() - ($top_nav.outerHeight() ?? 0))
        }

        if ($404_page.length > 0) {
            $404_page.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight() - ($top_nav.outerHeight() ?? 0))
        }
    }

    function setTopNavHeight() {
        if ($(window).width() < 992) {
            $navbar.css('--min-h', $top_nav.innerHeight() + 'px');
        }
    }

    window.onload = (e) => {
        resetSameHeight();
        setSameHeight();
        setMainContentHeight();
        setTopNavHeight();
    }

    window.onresize = (e) => {
        setMainContentHeight();
        resetSameHeight();
        setSameHeight();
        setTopNavHeight();
    }

    let $preloader = $('[data-preloader="load"]');

    if ($preloader.length > 0) {
        setTimeout((e) => {
            $('body').addClass('loaded');
            $preloader.addClass('loaded');
        }, 150);
    }

    console.log("%cMade with ♥ by Uniqoders - https://uniqoders.com", "color:#403c5e; font-size:36px")
})